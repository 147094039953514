$play-button-width: 68px;
$play-button-height: 48px;

.huh_video {

  .video-wrapper {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 */

    @media print {
      padding-bottom: 0;
      height: auto;
      width: auto;
      overflow: visible;
    }

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      border: 0;
      z-index: -1;
    }
  }

  .video-thumbnail {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .image_container {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      margin: 0;

      img {
        width: unset;
        height: unset;
        min-height: 100%;
        min-width: 100%;
        max-width: unset;
        max-height: unset;
        margin: auto;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }

      .play-button {
        background-image: url('../img/play.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: $play-button-width;
        height: $play-button-height;
        opacity: .8;

        transition: all .4s cubic-bezier(0.0, 0.0, 0.2, 1);

        &.youtube {
          background-image: url("../img/youtube_play.svg");
        }

        @media print {
          display: none;
        }
      }

      .duration {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .8);
        color: #fff;
        font-family: "Lucida Console", Monaco, monospace;
        font-size: .75rem;
        padding: 4px 7px;
        border: 1px solid #000;
      }
    }

    &:hover {
      .image_container {
        .play-button {
          opacity: 1;

          &.youtube {
            background-image: url("../img/youtube_play_color.svg");
          }
        }
      }
    }
  }

  .video-container {
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;

    iframe {
      height: 100%;
      opacity: 1;
      transition: 500ms opacity linear;
      z-index: 1;
    }

    &.video-hidden {
      display: none;

      iframe {
        opacity: 0;
      }
    }

    &.initialize {
      height: 0;
      padding: 0;
      display: block;

      iframe {
        height: 0;
      }
    }
  }

  .video-text {
    margin-top: 1rem;
  }
}
